.Contact {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.Contact-call-to-action {
  width: 50%;
  margin: 2rem;
}
.Contact-header {
  margin-bottom: 2rem;
}
.Contact-quote {
  width: 50%;
}
.Contact-email {
  margin: 1rem 0 -0.1rem 0;
}
.Contact-phone {
  text-align: left;
}

.fa-envelope {
  margin-bottom: 1rem;
}

@media all and (min-width: 768px) {
  .Contact-header {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .Contact-quote {
    font-size: 1.5rem;
  }
  .fa-envelope {
    font-size: 1.5rem;
  }
  .Contact-email,
  .Contact-phone {
    font-size: 1.5rem;
  }
}
