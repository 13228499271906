.Projects {
  margin: 1rem;
  text-align: center;
}
.Project-card {
  border: slategray 1px solid;
  border-radius: 12px;
  height: 25rem;
  margin: 2rem 0;
  position: relative;
  color: white;
}
.Project-overlay {
  height: 100%;
  width: 100%;
  background: rgba(235, 234, 233, 0.95);
  opacity: 0.9;
  z-index: 1;
  position: absolute;
  top: 0;
  border-radius: 12px;
}
.Project-image {
  height: 100%;
  z-index: -1;
}
.Project-info-container {
  position: absolute;
  z-index: 1;
  color: black;
  top: 0;
}

.Project-name {
  margin: 1rem;
  font-weight: 700;
}
.Project-info {
  margin: 1rem;
  font-size: 1.1rem;
}
.Project-stack {
  margin: 1rem;
  font-weight: 900;
}

.Project-fa {
  color: black;
}
.Project-link {
  margin: 1rem;
}
@media all and (min-width: 768px) {
  .Projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .Project-header {
    grid-column: 1 / span 2;
    font-size: 3rem;
    margin-bottom: 3rem;
  }
  .Project-card {
    margin: 1rem;
    height: 22.5rem;
    font-size: 1rem;
  }
  .Project-name {
    font-size: 2rem;
  }
}
@media all and (min-width: 1025px) {
  .Project-info {
    opacity: 0;
    transition: opacity 300ms;
  }

  .Project-card:hover .Project-info {
    opacity: 1;
  }
}
