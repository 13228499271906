.About {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.About-paragraph {
  margin-bottom: 0;
}
.About-about-me {
  margin-bottom: 3rem;
}
hr {
  width: 50%;
  border-top: 0.5rem dotted rgba(255, 99, 71, 0.516);
  margin: 2rem auto;
}

@media all and (min-width: 768px) {
  .About {
    margin: 2rem;
  }
  .About-about-me {
    font-size: 3rem;
  }
  .About-section-header {
    width: 100%;
    text-align: left;
    margin: 1rem;
    font-weight: 700;
  }
  .About-paragraph {
    font-size: 1.5rem;
  }
}
