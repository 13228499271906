@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800|Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  background-color: rgba(173, 216, 230, 0.523);
} */

.App-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  font-family: "Montserrat", sans-serif;
}
.App-navigation {
  display: none;
}
h2 {
  font-family: "Pacifico", cursive;
}
a {
  cursor: pointer;
}

.App-slide-menu {
  height: 100%;
  width: 50%;
  background-color: rgba(211, 211, 211, 0.763);
  position: fixed;
  opacity: 0.9;
  z-index: 2;
}

.App-bars-container {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
}
.App-slide-menu {
  padding: 1.5rem;
  border-radius: 12px;
}
.App-unordered-list {
  list-style: none;
}
.App-navigation-link {
  color: black;
}
li {
  margin: 1rem 0;
}

.App-navigation-link:hover {
  color: Tomato;
}
.fa {
  color: black;
  cursor: pointer;
}
.fa:hover {
  color: tomato;
}
.App-transparent-text {
  opacity: 0;
}

.hidden {
  display: none;
}
@media all and (min-width: 500px) {
  .App-bars-container {
    display: none;
  }
  .App-slide-menu {
    display: none;
  }
  .App-navigation {
    display: grid;
    margin: 1rem 0;
  }
  .App-unordered-list {
    display: flex;
    justify-content: space-evenly;
  }
  .App-navigation-link {
    font-size: 1.5rem;
  }
}

.Projects {
  margin: 1rem;
  text-align: center;
}
.Project-card {
  border: slategray 1px solid;
  border-radius: 12px;
  height: 25rem;
  margin: 2rem 0;
  position: relative;
  color: white;
}
.Project-overlay {
  height: 100%;
  width: 100%;
  background: rgba(235, 234, 233, 0.95);
  opacity: 0.9;
  z-index: 1;
  position: absolute;
  top: 0;
  border-radius: 12px;
}
.Project-image {
  height: 100%;
  z-index: -1;
}
.Project-info-container {
  position: absolute;
  z-index: 1;
  color: black;
  top: 0;
}

.Project-name {
  margin: 1rem;
  font-weight: 700;
}
.Project-info {
  margin: 1rem;
  font-size: 1.1rem;
}
.Project-stack {
  margin: 1rem;
  font-weight: 900;
}

.Project-fa {
  color: black;
}
.Project-link {
  margin: 1rem;
}
@media all and (min-width: 768px) {
  .Projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .Project-header {
    grid-column: 1 / span 2;
    font-size: 3rem;
    margin-bottom: 3rem;
  }
  .Project-card {
    margin: 1rem;
    height: 22.5rem;
    font-size: 1rem;
  }
  .Project-name {
    font-size: 2rem;
  }
}
@media all and (min-width: 1025px) {
  .Project-info {
    opacity: 0;
    -webkit-transition: opacity 300ms;
    transition: opacity 300ms;
  }

  .Project-card:hover .Project-info {
    opacity: 1;
  }
}

.About {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.About-paragraph {
  margin-bottom: 0;
}
.About-about-me {
  margin-bottom: 3rem;
}
hr {
  width: 50%;
  border-top: 0.5rem dotted rgba(255, 99, 71, 0.516);
  margin: 2rem auto;
}

@media all and (min-width: 768px) {
  .About {
    margin: 2rem;
  }
  .About-about-me {
    font-size: 3rem;
  }
  .About-section-header {
    width: 100%;
    text-align: left;
    margin: 1rem;
    font-weight: 700;
  }
  .About-paragraph {
    font-size: 1.5rem;
  }
}

.Contact {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.Contact-call-to-action {
  width: 50%;
  margin: 2rem;
}
.Contact-header {
  margin-bottom: 2rem;
}
.Contact-quote {
  width: 50%;
}
.Contact-email {
  margin: 1rem 0 -0.1rem 0;
}
.Contact-phone {
  text-align: left;
}

.fa-envelope {
  margin-bottom: 1rem;
}

@media all and (min-width: 768px) {
  .Contact-header {
    font-size: 3rem;
    margin-bottom: 1rem;
  }
  .Contact-quote {
    font-size: 1.5rem;
  }
  .fa-envelope {
    font-size: 1.5rem;
  }
  .Contact-email,
  .Contact-phone {
    font-size: 1.5rem;
  }
}

.Home-main-container {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
}
.Home-profile-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home-profile {
  width: 10rem;
}

.Home-title {
  margin: 1rem;
}
.Home-description {
  text-align: center;
}
.Home-links {
  display: none;
}
.Home-name {
  margin-bottom: 1rem;
}

@media all and (min-width: 500px) {
  .Home-links {
    display: flex;
    width: 60%;
    justify-content: space-between;
    margin: 3rem 0;
  }
}

@media all and (min-width: 768px) {
  .Home-name {
    font-size: 3rem;
  }
  .Home-profile {
    width: 15rem;
  }
  .Home-title {
    font-size: 2rem;
    margin: 2rem;
  }
  .Home-description {
    text-align: center;
    padding: 0 3rem;
    font-size: 1.5rem;
  }
}

