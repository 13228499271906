@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800|Pacifico&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body {
  background-color: rgba(173, 216, 230, 0.523);
} */

.App-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: relative;
  font-family: "Montserrat", sans-serif;
}
.App-navigation {
  display: none;
}
h2 {
  font-family: "Pacifico", cursive;
}
a {
  cursor: pointer;
}

.App-slide-menu {
  height: 100%;
  width: 50%;
  background-color: rgba(211, 211, 211, 0.763);
  position: fixed;
  opacity: 0.9;
  z-index: 2;
}

.App-bars-container {
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
}
.App-slide-menu {
  padding: 1.5rem;
  border-radius: 12px;
}
.App-unordered-list {
  list-style: none;
}
.App-navigation-link {
  color: black;
}
li {
  margin: 1rem 0;
}

.App-navigation-link:hover {
  color: Tomato;
}
.fa {
  color: black;
  cursor: pointer;
}
.fa:hover {
  color: tomato;
}
.App-transparent-text {
  opacity: 0;
}

.hidden {
  display: none;
}
@media all and (min-width: 500px) {
  .App-bars-container {
    display: none;
  }
  .App-slide-menu {
    display: none;
  }
  .App-navigation {
    display: grid;
    margin: 1rem 0;
  }
  .App-unordered-list {
    display: flex;
    justify-content: space-evenly;
  }
  .App-navigation-link {
    font-size: 1.5rem;
  }
}
